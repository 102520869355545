import {
  FETCH_LEVEL,
} from "./actions.type";
import {
  SET_FETCH_LEVEL,
  SET_LEVEL_MESSAGE,
} from "./mutations.type";
import { apiPost } from "@/utils/apiHandler";

const state = {
  levelData: [],
  levelMessage: "",
};

const getters = {
  getLevelData(state) {
    return state.levelData;
  },
  getLevelMessage(state) {
    return state.levelMessage;
  },
};

const actions = {
  [FETCH_LEVEL]({ commit }, payload) {
    return apiPost("/stage", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_LEVEL_MESSAGE, data.error);
      } else {
        commit(SET_FETCH_LEVEL, data.data);
      }
    });
  },
};

const mutations = {
  [SET_FETCH_LEVEL](state, levelData) {
    state.levelData = levelData;
  },
  [SET_LEVEL_MESSAGE](state, message) {
    state.levelMessage = message;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
