// UTILS
export const HIDE_SIDEBAR = "hideSidebar";
export const FETCH_PROVINSI = "fetchProvinsi";
export const FETCH_KOTA = "fetchKota";
export const FETCH_KECAMATAN = "fetchKecamatan";
export const LOADING = "loading";

// AUTH
export const POST_LOGIN_ADMIN = "postLoginAdmin";
export const POST_RESET_PASSWORD = "postResetPassword";

// DASHBOARD
export const FETCH_DASHBOARD_DBSISWA = "fetchDashboardDbsiswa"

// DASHBOARD
export const FETCH_PROFILE_SISWA = "fetchProfileSiswa";
export const FETCH_ABSENSI_SISWA = "fetchAbsensiSiswa";
export const FETCH_AKTIVITAS_SISWA = "fetchAktivitasSisa";
export const FETCH_EVENT_SISWA = "fetchEventSiswa";

// STUDENT
export const FETCH_DETAIL_STUDENT = "fetchDetailStudent";
export const UPDATE_STUDENT = "updateStudent";
export const POST_CHANGE_PASSWORD = "postChangePassword";

// LEVEL
export const FETCH_LEVEL = "fetchLevel";

// ABSENSI
export const FETCH_ABSENSI = "fetchAbsensi";
export const FETCH_DETAIL_ABSENSI = "fetchDetailAbsensi";
