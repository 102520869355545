import {
  FETCH_DETAIL_STUDENT,
  UPDATE_STUDENT,
  POST_CHANGE_PASSWORD,
} from "./actions.type";
import {
  SET_FETCH_DETAIL_STUDENT,
  SET_UPDATE_STUDENT,
  SET_STUDENT_MESSAGE,
} from "./mutations.type";
import { apiPost } from "@/utils/apiHandler";
import Vue from "vue";

const state = {
  detailStudentData: [],
  studentMessage: "",
};

const getters = {
  getDetailStudentData(state) {
    return state.detailStudentData;
  },
  getStudentMessage(state) {
    return state.studentMessage;
  },
};

const actions = {
  [FETCH_DETAIL_STUDENT]({ commit }, payload) {
    return apiPost("/student/detail", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_STUDENT_MESSAGE, data.error);
      } else {
        commit(SET_FETCH_DETAIL_STUDENT, data.data);
      }
    });
  },
  [UPDATE_STUDENT]({ commit }, payload) {
    return apiPost("/student/update", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_STUDENT_MESSAGE, data.error);
      } else {
        Vue.toasted.success(data.message, {
          duration: 3000,
          keepOnHover: true,
        });
        commit(SET_STUDENT_MESSAGE, data.message);
      }
    });
  },
  [POST_CHANGE_PASSWORD]({ commit }, payload) {
    return apiPost("/profile/change-password", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_STUDENT_MESSAGE, data.error);
      } else {
        Vue.toasted.success(data.message, {
          duration: 3000,
          keepOnHover: true,
        });
        commit(SET_STUDENT_MESSAGE, data.message);
      }
    });
  },
};

const mutations = {
  [SET_FETCH_DETAIL_STUDENT](state, detailStudentData) {
    state.detailStudentData = detailStudentData;
  },
  [SET_STUDENT_MESSAGE](state, message) {
    state.studentMessage = message;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
