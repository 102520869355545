<template>
  <v-toolbar class="px-9" height="88" elevation="1" flat>
    <v-toolbar-title class="d-flex flex-column my-4">
      <div class="text-title-grey">Halo {{ user }}</div>
      <div class="text-paragraph-14-grey">{{ date }}</div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-sheet outlined color="#0eb7b0" rounded="lg" style="cursor: pointer">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-card
            v-bind="attrs"
            v-on="on"
            class="profile-box"
            outlined
            rounded="lg"
            width="197"
            height="59"
          >
            <v-row>
              <v-col>
                <v-icon x-large class="my-2 ml-3">mdi-account-circle-outline</v-icon>
                <!-- <div class="photo-profile-box my-2 ml-3"></div> -->
              </v-col>
              <v-col>
                <div class="my-1">
                  <div class="text-paragraph-18-grey">{{ userName }}</div>
                  <div class="text-paragraph-12-grey">Siswa</div>
                </div>
              </v-col>
              <v-col>
                <v-icon class="my-4">mdi-menu-down</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title class="d-flex justify-center">
              <div @click="logOut" style="cursor: pointer">
                Log Out <v-icon class="ml-2">mdi-logout</v-icon>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-sheet>
  </v-toolbar>
</template>

<script>
import moment from "moment";

export default {
  name: "Navbar",
  components: {},
  data() {
    return {
      user: "",
      role: "",
    };
  },
  computed: {
    userRole() {
      if (this.role === "2") {
        return "Tutor";
      } else if (this.role === "3") {
        return "Admin";
      } else if (this.role === "4") {
        return "Akademik";
      } else if (this.role === "5") {
        return "Developer";
      }
    },
    userName() {
      if (this.user.length >= 5) {
        let concatedUser = this.user.split("");
        let newUser = "";
        for (let i = 0; i < 5; i++) {
          newUser += concatedUser[i];
        }
        return newUser + "...";
      } else {
        return this.user;
      }
    },
    date() {
      let newDate = Date.now();
      return moment(newDate).locale("id").format("dddd, D MMMM YYYY");
    },
  },
  mounted() {
    let userLocal = JSON.parse(localStorage.getItem("user_data"));
    this.user = userLocal.username;
    this.role = userLocal.role_id;
  },
  methods: {
    logOut() {
      localStorage.removeItem("user_data");
      localStorage.removeItem("access_token");
      localStorage.removeItem("expire");
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style scoped>
.photo-profile-box {
  width: 41px;
  height: 41px;
  border-radius: 100%;
  background-color: #737373;
}

.text-title-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 700;
  font-size: 20px;
  line-height: auto;
  color: #737373;
}

.text-paragraph-18-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 18px;
  line-height: auto;
  color: #737373;
}

.text-paragraph-14-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 14px;
  line-height: auto;
  color: #737373;
}

.text-paragraph-12-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 300;
  font-size: 12px;
  line-height: auto;
  color: #737373;
}
</style>
