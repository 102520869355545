<template>
  <div>
    <Navbar />
    <div class="data-container ma-0">
      <v-card>
        <v-progress-linear v-if="fetchData" indeterminate></v-progress-linear>
        <div class="pt-6 px-6 pb-7">
          <div class="font-600-14 mb-10">Detail Siswa Hippocampus</div>
          <v-row>
            <v-col cols="4">
              <div v-if="!urlFotoSiswa" class="mx-auto photo-box">
                <v-icon large>mdi-camera-outline</v-icon>
              </div>
              <img
                v-else
                class="mx-auto uploaded-photo"
                :src="urlFotoSiswa"
                alt=""
              />
              <div class="font-400-14 text-center mt-6">
                <div class="mb-2">{{ getDetailStudentData.name }}</div>
                <div class="grey2--text">
                  {{ getDetailStudentData.username }}
                </div>
                <div class="grey2--text">{{ getDetailStudentData.email }}</div>
                <div class="mb-2">{{ getDetailStudentData.phone }}</div>
                <div>
                  Status Acount:
                  <v-chip color="primary" label small>Active</v-chip>
                </div>
              </div>
              <div class="d-flex mt-14">
                <v-btn
                  @click="dialogEdit = true"
                  class="rounded-lg mx-auto mt-2"
                  color="primary"
                  height="36"
                >
                  Update Profile
                </v-btn>
                <v-btn
                  @click="showDialogChangePassword"
                  class="rounded-lg mx-auto mt-2"
                  color="primary"
                  height="36"
                >
                  Update Password
                </v-btn>
              </div>
            </v-col>
            <v-col cols="3">
              <label class="font-400-12">Jenis Kelamin</label>
              <v-select
                :items="dataJenisKelamin"
                item-text="text"
                item-value="value"
                :disabled="true"
                v-model="jenisKelamin"
                placeholder="Jenis Kelamin"
                outlined
                :rules="[(v) => !!v || 'Silahkan pilih jenis kelamin']"
                class="my-input mt-2"
              >
              </v-select>
              <label class="font-400-12">Kota</label>
              <v-autocomplete
                outlined
                :items="getRegenciesData"
                item-text="name"
                item-value="id"
                :disabled="true"
                v-model="kota"
                :rules="[(v) => !!v || 'Kota harus diisi']"
                :loading="loadingKota"
                placeholder="Kota"
                @change="fetchKecamatan"
                class="my-input mt-2"
              ></v-autocomplete>
              <label class="font-400-12">Alamat</label>
              <v-text-field
                outlined
                :disabled="true"
                v-model="alamat"
                :rules="[(v) => !!v || 'Alamat harus diisi']"
                placeholder="Alamat"
                class="my-input mt-2"
                type="text"
              ></v-text-field>
              <label class="font-400-12">Jurusan</label>
              <v-text-field
                outlined
                :disabled="true"
                v-model="jurusan"
                :rules="[(v) => !!v || 'Jurusan harus diisi']"
                placeholder="Jurusan"
                class="my-input mt-2"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <label class="font-400-12">Provinsi</label>
              <v-autocomplete
                outlined
                :items="getProvincesData"
                item-text="name"
                item-value="id"
                :disabled="true"
                v-model="provinsi"
                :rules="[(v) => !!v || 'Provinsi harus diisi']"
                placeholder="Provinsi"
                @change="fetchKota"
                class="my-input mt-2"
              >
              </v-autocomplete>
              <label class="font-400-12">Kecamatan</label>
              <v-autocomplete
                outlined
                :items="getDistrictsData"
                item-text="name"
                item-value="id"
                :disabled="true"
                v-model="kecamatan"
                :rules="[(v) => !!v || 'Kecamatan harus diisi']"
                :loading="loadingKecamatan"
                placeholder="Kecamatan"
                class="my-input mt-2"
              ></v-autocomplete>
              <label class="font-400-12">Sekolah</label>
              <v-text-field
                outlined
                :disabled="true"
                :rules="[(v) => !!v || 'Sekolah harus diisi']"
                placeholder="Sekolah"
                class="my-input mt-2"
                type="text"
              ></v-text-field>
              <label class="font-400-12">Kelas</label>
              <v-select
                :items="dataKelas"
                item-text="text"
                item-value="value"
                v-model="kelas"
                placeholder="Kelas"
                outlined
                :disabled="true"
                :rules="[(v) => !!v || 'Silahkan pilih kelas']"
                class="my-input mt-2"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <v-dialog v-model="dialogEdit" max-width="592">
      <v-card style="border-radius: 20px">
        <v-card-title>
          Update Profile <v-spacer></v-spacer>
          <v-icon @click="dialogEdit = false">mdi-close</v-icon>
        </v-card-title>
        <v-progress-linear v-if="fetchData" indeterminate></v-progress-linear>
        <v-divider v-else></v-divider>
        <v-card-text>
          <v-form ref="formEdit">
            <div class="d-flex flex-column mt-14">
              <div v-if="!urlFotoSiswa" class="mx-auto photo-box">
                <v-icon large>mdi-camera-outline</v-icon>
              </div>
              <img
                v-else
                class="mx-auto uploaded-photo"
                :src="urlFotoSiswa"
                alt=""
              />
              <input
                ref="inputFoto"
                class="d-none"
                type="file"
                @change="onFileChangeFoto"
              />
              <v-btn
                @click="uploadFoto"
                class="rounded-lg mx-auto mt-6"
                color="primary"
                width="117"
                height="36"
              >
                Upload Foto
              </v-btn>
              <div v-if="!validFoto" class="text-center mt-2">
                <small class="danger--text"> Pilih foto maksimal 1 mb </small>
              </div>
              <div v-else class="mt-2" style="height: 24px"></div>
            </div>
            <v-divider class="my-8"></v-divider>
            <v-row>
              <v-col>
                <label class="font-400-12">Nama Siswa</label>
                <v-text-field
                  outlined
                  v-model="namaSiswa"
                  :rules="[(v) => !!v || 'Nama Siswa harus diisi']"
                  placeholder="Nama Siswa"
                  class="my-input mt-2"
                  type="text"
                ></v-text-field>
                <label class="font-400-12">No. Telp</label>
                <v-text-field
                  outlined
                  v-model="noTelp"
                  :rules="[(v) => !!v || 'No. Telp harus diisi']"
                  placeholder="No. Telp"
                  class="my-input mt-2"
                  type="number"
                ></v-text-field>
                <label class="font-400-12">Jenis Kelamin</label>
                <v-select
                  :items="dataJenisKelamin"
                  item-text="text"
                  item-value="value"
                  v-model="jenisKelamin"
                  placeholder="Jenis Kelamin"
                  outlined
                  :rules="[(v) => !!v || 'Silahkan pilih jenis kelamin']"
                  class="my-input mt-2"
                >
                </v-select>
                <label class="font-400-12">Kota</label>
                <v-autocomplete
                  v-model="kota"
                  outlined
                  :items="getRegenciesData"
                  item-text="name"
                  item-value="id"
                  :disabled="!provinsi"
                  :rules="[(v) => !!v || 'Kota harus diisi']"
                  :loading="loadingKota"
                  placeholder="Kota"
                  @change="fetchKecamatan"
                  class="my-input mt-2"
                ></v-autocomplete>
                <label class="font-400-12">Alamat</label>
                <v-text-field
                  v-model="alamat"
                  outlined
                  :rules="[(v) => !!v || 'Alamat harus diisi']"
                  placeholder="Alamat"
                  class="my-input mt-2"
                  type="text"
                ></v-text-field>
                <label class="font-400-12">Jurusan</label>
                <v-select
                  :items="dataJurusan"
                  v-model="jurusan"
                  placeholder="Jurusan"
                  outlined
                  :rules="[(v) => !!v || 'Jurusan harus diisi']"
                  class="my-input mt-2"
                >
                </v-select>
              </v-col>
              <v-col>
                <label class="font-400-12">Username</label>
                <v-text-field
                  v-model="username"
                  outlined
                  :rules="[(v) => !!v || 'Email harus diisi']"
                  placeholder="Username"
                  class="my-input mt-2"
                  type="text"
                ></v-text-field>
                <label class="font-400-12">Email</label>
                <v-text-field
                  v-model="email"
                  outlined
                  :rules="[(v) => !!v || 'Email harus diisi']"
                  placeholder="Email"
                  class="my-input mt-2"
                  type="text"
                ></v-text-field>
                <label class="font-400-12">Provinsi</label>
                <v-autocomplete
                  v-model="provinsi"
                  outlined
                  :items="getProvincesData"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'Provinsi harus diisi']"
                  placeholder="Provinsi"
                  @change="fetchKota"
                  class="my-input mt-2"
                >
                </v-autocomplete>
                <label class="font-400-12">Kecamatan</label>
                <v-autocomplete
                  v-model="kecamatan"
                  outlined
                  :items="getDistrictsData"
                  item-text="name"
                  item-value="id"
                  :disabled="!kota"
                  :rules="[(v) => !!v || 'Kecamatan harus diisi']"
                  :loading="loadingKecamatan"
                  placeholder="Kecamatan"
                  class="my-input mt-2"
                ></v-autocomplete>
                <label class="font-400-12">Sekolah</label>
                <v-text-field
                  v-model="sekolah"
                  outlined
                  :rules="[(v) => !!v || 'Sekolah harus diisi']"
                  placeholder="Sekolah"
                  class="my-input mt-2"
                  type="text"
                ></v-text-field>
                <label class="font-400-12">Kelas</label>
                <v-select
                  :items="dataKelas"
                  item-text="text"
                  item-value="value"
                  v-model="kelas"
                  placeholder="Kelas"
                  outlined
                  :rules="[(v) => !!v || 'Silahkan pilih kelas']"
                  class="my-input mt-2"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 d-flex justify-end">
          <v-btn
            @click="dialogConfirmEdit = true"
            class="rounded-lg mb-10"
            color="primary"
            height="36"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditPassword" max-width="592">
      <v-card style="border-radius: 20px">
        <v-card-title>
          Update Password <v-spacer></v-spacer>
          <v-icon @click="dialogEditPassword = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formChangePassword">
            <v-row class="mt-4">
              <v-col>
                <label class="font-400-12">Password Lama</label>
                <v-text-field
                  outlined
                  v-model="passwordLama"
                  :rules="[(v) => !!v || 'Password Lama harus diisi']"
                  placeholder="Password Lama"
                  class="my-input mt-2"
                  type="text"
                  :type="showOldPassword ? 'password' : 'text'"
                  :append-icon="
                    showOldPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                  "
                  @click:append="() => (showOldPassword = !showOldPassword)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">mdi-lock</v-icon>
                  </template>
                </v-text-field>
                <v-divider class="mb-5"></v-divider>
                <label class="font-400-12">Password Baru</label>
                <v-text-field
                  outlined
                  v-model="passwordBaru"
                  :rules="[(v) => !!v || 'Password Baru harus diisi']"
                  placeholder="Password Baru"
                  class="my-input mt-2"
                  type="text"
                  :type="showNewPassword ? 'password' : 'text'"
                  :append-icon="
                    showNewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                  "
                  @click:append="() => (showNewPassword = !showNewPassword)"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">mdi-lock</v-icon>
                  </template>
                </v-text-field>
                <label class="font-400-12">Konfirmasi Password Baru</label>
                <v-text-field
                  outlined
                  v-model="confirmPasswordBaru"
                  :rules="[
                    (v) => !!v || 'Konfirmasi Password Baru harus diisi',
                    (v) =>
                      v != passwordBaru
                        ? 'Konfirmasi Password Tidak Sesuai'
                        : true,
                  ]"
                  placeholder="Konfirmasi Password Baru"
                  class="my-input mt-2"
                  type="text"
                  :type="showConfirmPassword ? 'password' : 'text'"
                  :append-icon="
                    showConfirmPassword
                      ? 'mdi-eye-off-outline'
                      : 'mdi-eye-outline'
                  "
                  @click:append="
                    () => (showConfirmPassword = !showConfirmPassword)
                  "
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">mdi-lock</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 d-flex justify-end">
          <v-btn
            @click="changePassword"
            :loading="fetchData"
            class="rounded-lg mb-10"
            color="primary"
            height="36"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DialogConfirm
      v-model="dialogConfirmEdit"
      :loading="fetchData"
      title="Siswa"
      @closeDialog="dialogConfirmEdit = false"
      @action="editProfil"
    ></DialogConfirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "../Navbar/Navbar.vue";
import DialogConfirm from "../Base/DialogConfirm.vue";
import {
  FETCH_DETAIL_STUDENT,
  UPDATE_STUDENT,
  POST_CHANGE_PASSWORD,
  FETCH_LEVEL,
  FETCH_PROVINSI,
  FETCH_KOTA,
  FETCH_KECAMATAN,
  LOADING,
} from "@/store/actions.type";

export default {
  name: "Class",
  components: { Navbar, DialogConfirm },
  data() {
    return {
      fetchData: false,
      loadingKota: false,
      loadingKecamatan: false,
      dialogEdit: false,
      dialogConfirmEdit: false,
      dialogEditPassword: false,
      validFoto: true,
      urlFotoSiswa: null,

      showOldPassword: true,
      showNewPassword: true,
      showConfirmPassword: true,

      dataJenisKelamin: [
        { text: "Laki-laki", value: "male" },
        { text: "Perempuan", value: "female" },
      ],
      dataJurusan: ["IPA", "IPS", "UMUM"],
      dataKelas: [],

      // Input Profil
      fotosiswa: null,
      namaSiswa: null,
      noTelp: null,
      jenisKelamin: null,
      kota: null,
      alamat: null,
      jurusan: null,
      username: null,
      email: null,
      provinsi: null,
      kecamatan: null,
      sekolah: null,
      kelas: null,

      // Input Change Password
      passwordLama: null,
      passwordBaru: null,
      confirmPasswordBaru: null,
    };
  },
  computed: {
    ...mapGetters([
      "getDetailStudentData",
      "getLevelData",
      "getProvincesData",
      "getRegenciesData",
      "getDistrictsData",
    ]),
  },
  async mounted() {
    this.fetchData = true;
    await this.$store.dispatch(LOADING, true);
    await this.$store.dispatch(FETCH_DETAIL_STUDENT, {
      user_id: JSON.parse(localStorage.getItem("user_data")).id,
    });
    this.fillField();
    await this.$store.dispatch(FETCH_LEVEL, "");
    await this.$store.dispatch(FETCH_PROVINSI);
    this.getLevelData.forEach((data) => {
      let dataKelas = "";
      if (data.class != "0") dataKelas = `${data.level}-${data.class}`;
      else dataKelas = data.level;
      this.dataKelas.push({ text: dataKelas, value: data.id });
    });
    await this.$store.dispatch(LOADING, false);
    this.fetchData = false;
  },
  methods: {
    uploadFoto() {
      this.isSelecting = true;
      this.validFoto = true;

      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      // Trigger click on the FileInput
      this.$refs.inputFoto.click();
    },
    onFileChangeFoto(e) {
      this.validFoto = true;
      if (!e.target.files[0]) {
        this.fotoSiswa = this.fotoSiswa;
        this.urlFotoSiswa = this.urlFotoSiswa;
      } else {
        if (e.target.files[0].size > 1000000) {
          this.validFoto = false;
          return;
        }
        this.fotoSiswa = e.target.files[0];
        this.urlFotoSiswa = URL.createObjectURL(this.fotoSiswa);
      }
    },
    async fetchKota(idProvinsi) {
      this.loadingKota = true;
      await this.$store.dispatch(FETCH_KOTA, idProvinsi);
      this.loadingKota = false;
    },
    async fetchKecamatan(idKota) {
      this.loadingKecamatan = true;
      await this.$store.dispatch(FETCH_KECAMATAN, idKota);
      this.loadingKecamatan = false;
    },

    async reFetchData() {
      this.fetchData = true;
      await this.$store.dispatch(FETCH_DETAIL_STUDENT, {
        user_id: JSON.parse(localStorage.getItem("user_data")).id,
      });
      if (this.$refs.formChangePassword) {
        this.$refs.formChangePassword.reset();
      }
      this.fillField();
      this.fetchData = false;
      this.dialogConfirmEdit = false;
      this.dialogEdit = false;
      this.dialogEditPassword = false;
    },
    async fillField() {
      await this.$store.dispatch(
        FETCH_KOTA,
        this.getDetailStudentData.province_id
      );
      await this.$store.dispatch(
        FETCH_KECAMATAN,
        this.getDetailStudentData.regency_id
      );

      this.urlFotoSiswa = this.getDetailStudentData.image_url;
      this.namaSiswa = this.getDetailStudentData.name;
      this.noTelp = this.getDetailStudentData.phone;
      this.jenisKelamin = this.getDetailStudentData.gender;
      this.provinsi = this.getDetailStudentData.province_id;
      this.kota = this.getDetailStudentData.regency_id;
      this.kecamatan = this.getDetailStudentData.sub_district_id;
      this.alamat = this.getDetailStudentData.address;
      this.jurusan = this.getDetailStudentData.major;
      this.username = this.getDetailStudentData.username;
      this.email = this.getDetailStudentData.email;
      this.sekolah = this.getDetailStudentData.school_name;
      this.kelas = this.getDetailStudentData.stage_id;
    },

    showDialogChangePassword() {
      this.dialogEditPassword = true;
    },

    async editProfil() {
      if (this.$refs.formEdit.validate()) {
        this.fetchData = true;
        await this.$store.dispatch(LOADING, true);
        const dataEdit = new FormData();

        dataEdit.append("id", this.getDetailStudentData.id);
        dataEdit.append(
          "user_id",
          JSON.parse(localStorage.getItem("user_data")).id
        );
        dataEdit.append("name", this.namaSiswa);
        dataEdit.append("username", this.username);
        dataEdit.append("phone", this.noTelp);
        dataEdit.append("email", this.email);
        dataEdit.append("gender", this.jenisKelamin);
        dataEdit.append("province", this.provinsi);
        dataEdit.append("regency", this.kota);
        dataEdit.append("sub_district", this.kecamatan);
        dataEdit.append("address", this.alamat);
        dataEdit.append("school_name", this.sekolah);
        dataEdit.append("major", this.jurusan);
        dataEdit.append("stage_id", this.kelas);
        dataEdit.append("role_id", 1);
        dataEdit.append("image_file", this.fotoSiswa);
        dataEdit.append("status", "active");

        await this.$store.dispatch(UPDATE_STUDENT, dataEdit);
        this.fetchData = false;
        this.reFetchData();
        await this.$store.dispatch(LOADING, false);
      } else this.dialogConfirmEdit = false;
    },
    async changePassword() {
      if (this.$refs.formChangePassword.validate()) {
        let data = {
          user_id: JSON.parse(localStorage.getItem("user_data")).id,
          old_password: this.passwordLama,
          new_password: this.passwordBaru,
        };
        this.fetchData = true;
        await this.$store.dispatch(LOADING, true);
        this.$store.dispatch(POST_CHANGE_PASSWORD, data);
        await this.$store.dispatch(LOADING, false);
        this.fetchData = false;
        this.reFetchData()
      }
    },
  },
};
</script>

<style>
.data-container {
  padding-top: 20px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 120px;
  overflow-y: scroll;
  height: 100vh;
}
.photo-box {
  display: flex;
  justify-content: center;
  width: 162px;
  height: 172px;
  border: 1px solid black;
}
.uploaded-photo {
  display: flex;
  justify-content: center;
  object-fit: cover;
  width: 162px;
  height: 172px;
}
</style>
