import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/Login/Login.vue";
import AbsensiView from "../views/Dashboard/Absensi.vue";
import DashboardView from "../views/Dashboard/Dashboard.vue";
import ProfilView from "../views/Dashboard/Profil.vue";
import PrivacyPolicy from "../views/privacy-policy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
    // name: 'home',
    // component: HomeView
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/absensi",
    name: "absensi",
    component: AbsensiView,
    meta: { requiresAuth: true },
  },
  {
    path: "/profil",
    name: "profil",
    component: ProfilView,
    meta: { requiresAuth: true },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/:notFound",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const expire = localStorage.getItem("expire");
  const now = new Date();
  if (to.meta.requiresAuth) {
    if (now.getTime() > expire) {
      localStorage.removeItem("user_data");
      localStorage.removeItem("access_token");
      localStorage.removeItem("expire");
      next("/login");
    } else {
      next();
    }
  } else if (to.name === "login") {
    if (now.getTime() > expire) {
      localStorage.removeItem("user_data");
      localStorage.removeItem("access_token");
      localStorage.removeItem("expire");
      next();
    } else {
      next("/dashboard");
    }
  } else {
    next();
  }
});

export default router;
