import {
  FETCH_ABSENSI,
  FETCH_DETAIL_ABSENSI,
  SET_ABSENSI_MESSAGE,
} from "./actions.type";
import { SET_FETCH_ABSENSI, SET_FETCH_DETAIL_ABSENSI } from "./mutations.type";
import { apiPost } from "@/utils/apiHandler";
import Vue from "vue";

const state = {
  absensiData: [],
  detailAbsensiData: [],
  absensiMessage: "",
};

const getters = {
  getAbsensiData(state) {
    return state.absensiData;
  },
  getDetailAbsensiData(state) {
    return state.detailAbsensiData;
  },
  getAbsensiMessage(state) {
    return state.absensiMessage;
  },
};

const actions = {
  [FETCH_ABSENSI]({ commit }, payload) {
    return apiPost("/attandance/student", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_ABSENSI_MESSAGE, data.error);
      } else {
        commit(SET_FETCH_ABSENSI, data.data);
      }
    });
  },
  [FETCH_DETAIL_ABSENSI]({ commit }, payload) {
    return apiPost("/attandance/student/detail", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_ABSENSI_MESSAGE, data.error);
      } else {
        commit(SET_FETCH_DETAIL_ABSENSI, data.data);
      }
    });
  },
};

const mutations = {
  [SET_FETCH_ABSENSI](state, absensiData) {
    state.absensiData = absensiData;
  },
  [SET_FETCH_DETAIL_ABSENSI](state, detailAbsensiData) {
    state.detailAbsensiData = detailAbsensiData;
  },
  [SET_ABSENSI_MESSAGE](state, message) {
    state.absensiMessage = message;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
