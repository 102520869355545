<template>
  <div>
    <Navbar />
    <div class="data-container ma-0">
      <v-row>
        <v-col>
          <v-card class="py-5 px-7 rounded-hippo" style="height: 100%">
            <div class="font-400-18">Profile</div>
            <div class="d-flex mt-3">
              <img
                v-if="getDashboardSiswaDbData.profile.image_url"
                :src="getDashboardSiswaDbData.profile.image_url"
                class="image-siswa mr-4"
                alt="foto profile siswa"
              />
              <div v-else class="profile-image mr-4"></div>
              <div>
                <div class="font-600-14">
                  {{ getDashboardSiswaDbData.profile.name }}
                </div>
                <div class="font-400-14 grey2--text">
                  {{ getDashboardSiswaDbData.profile.username }}
                </div>
                <div class="font-400-14 grey2--text">
                  {{ getDashboardSiswaDbData.profile.email }}
                </div>
                <div class="font-400-14">
                  {{ getDashboardSiswaDbData.profile.phone }}
                </div>
                <div class="font-400-14 mt-2">
                  Status Account: {{ getDashboardSiswaDbData.profile.status }}
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-card class="py-4 px-7 rounded-hippo">
                <div class="d-flex align-center">
                  <img
                    src="@/assets/dashboard/Calendar.svg"
                    style="width: 36px; height: 36px"
                    class="mr-3"
                  />
                  <div>
                    <div class="font-600-12">Change Period</div>
                    <div class="font-400-12">Januari</div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card class="py-2 rounded-hippo">
                <div class="text-center">
                  <img
                    src="@/assets/dashboard/Pertemuan.svg"
                    style="width: 45px; height: 45px"
                    class=""
                  />
                  <div class="font-700-16">
                    {{ getDashboardSiswaDbData.total_of_meetings }}
                  </div>
                  <div class="font-500-12">Jumlah Pertemuan Belajar</div>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="py-2 rounded-hippo">
                <div class="text-center">
                  <img
                    src="@/assets/dashboard/Calendar.svg"
                    style="width: 45px; height: 45px"
                    class=""
                  />
                  <div class="font-700-16">
                    {{ getDashboardSiswaDbData.total_event }}
                  </div>
                  <div class="font-500-12">Jumlah Event diikuti</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="pb-5 pt-1 px-7 rounded-hippo" style="height: 100%">
            <v-calendar-weekly></v-calendar-weekly>
          </v-card>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-card class="py-5 px-7 rounded-hippo">
                <div class="d-flex">
                  <img
                    src="@/assets/dashboard/Check.svg"
                    style="width: 52px; height: 52px"
                    class="mr-3"
                  />
                  <div>
                    <div class="font-600-12">Siswa Hadir</div>
                    <div class="font-400-12">
                      {{ getDashboardSiswaDbData.attandance.present }}x
                      Pertemuan
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="py-5 px-7 rounded-hippo">
                <div class="d-flex">
                  <img
                    src="@/assets/dashboard/Izin.svg"
                    style="width: 52px; height: 52px"
                    class="mr-3"
                  />
                  <div>
                    <div class="font-600-12">Siswa Izin</div>
                    <div class="font-400-12">
                      {{ getDashboardSiswaDbData.attandance.permission }}x
                      Pertemuan
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="py-8 px-7 rounded-hippo">
            <div class="font-500-14 mb-5">Aktifitasku</div>
            <div>
              <v-card
                v-for="schecule in getDashboardSiswaDbData.list_schedule"
                class="rounded-hippo mb-4"
                :class="{
                  'regular': schecule.activity == 'Reguler Meeting',
                  'extraTime': schecule.activity == 'ExtraTime',
                  'ekstraKulikuler': schecule.activity == 'EkstraKulikuler',
                  'ekstraSkill': schecule.activity == 'EkstraSkill',
                  'event': schecule.activity == 'Event',
                }"
                height="100px"
              >
                <v-card-title class="font-600-12 py-1">
                  {{ schecule.name }}
                </v-card-title>
                <v-card-text class="grey2--text font-400-10">
                  <div>
                    <div>
                      <v-icon>mdi-calendar-blank-outline</v-icon>
                      {{ getDayName(schecule.date) }}, {{ schecule.date }}
                    </div>
                    <div>
                      <v-icon>mdi-clock-time-eight-outline</v-icon>
                      {{ schecule.time_start }}-{{ schecule.time_finish }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-card class="py-5 px-7 rounded-hippo">
                <div class="d-flex">
                  <img
                    src="@/assets/dashboard/Non-Check.svg"
                    style="width: 52px; height: 52px"
                    class="mr-3"
                  />
                  <div>
                    <div class="font-600-12">Siswa Absen</div>
                    <div class="font-400-12">
                      {{ getDashboardSiswaDbData.attandance.not_present }}x
                      Pertemuan
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="py-5 px-7 rounded-hippo">
                <div class="d-flex">
                  <img
                    src="@/assets/dashboard/Sakit.svg"
                    style="width: 52px; height: 52px"
                    class="mr-3"
                  />
                  <div>
                    <div class="font-600-12">Siswa Sakit</div>
                    <div class="font-400-12">
                      {{ getDashboardSiswaDbData.attandance.sick }}x Pertemuan
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { HIDE_SIDEBAR } from "../../store/actions.type";
import Navbar from "../Navbar/Navbar.vue";
import moment from "moment";
import { FETCH_DASHBOARD_DBSISWA, LOADING } from "@/store/actions.type";

export default {
  name: "Class",
  components: { Navbar },
  data() {
    return {
      fetchData: false,
    };
  },
  computed: {
    ...mapGetters(["getDashboardSiswaDbData"]),
  },
  async mounted() {
    this.fetchData = true;
    await this.$store.dispatch(LOADING, true);
    await this.$store.dispatch(HIDE_SIDEBAR, false);
    let data = {
      user_id: JSON.parse(localStorage.getItem("user_data")).id,
    };
    await this.$store.dispatch(FETCH_DASHBOARD_DBSISWA, data);
    await this.$store.dispatch(LOADING, false);
    this.fetchData = false;
  },
  methods: {
    getDayName(date) {
      return moment(date).locale("id").format("dddd");
    },
  },
};
</script>

<style>
.data-container {
  padding-top: 20px;
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 120px;
  overflow-y: scroll;
  height: 100vh;
}
.profile-image {
  width: 113px;
  height: 113px;
  border-radius: 20px;
  background-color: #f8f4f4;
}
.image-siswa {
  width: 113px;
  height: 113px;
  border-radius: 20px;
  object-fit: cover;
}
.regular {
  border-left: 15px solid #eb4335 !important;
}
.extraTime {
  border-left: 15px solid #feb068 !important;
}
.ekstraKulikuler {
  border-left: 15px solid #89C8FC !important;
}
.ekstraSkill {
  border-left: 15px solid #A6F986 !important;
}
.event {
  border-left: 15px solid #bababa !important;
}
</style>
