// UTILS
export const SET_HIDE_SIDEBAR = "setHideSidebar";
export const SET_FETCH_PROVINSI = "setFetchProvinsi";
export const SET_FETCH_KOTA = "setFetchKota";
export const SET_FETCH_KECAMATAN = "setFetchKecamatan";
export const SET_LOADING = "setLoading";

// AUTH
export const SET_LOGIN_AUTH_DATA = "setLoginAuthData";
export const SET_LOGIN_USER_DATA = "setLoginUserData";
export const SET_POST_RESET_PASSWORD = "setPostResetPassword";
export const SET_LOGIN_ERROR_MESSAGE = "setLoginErrorMessage";

// DASHBOARD
export const SET_FETCH_DASHBOARD_DBSISWA = "setFetchDashboardDbsiswa"

// DASHBOARD
export const SET_FETCH_PROFILE_SISWA = "setFetchProfileSiswa";
export const SET_FETCH_ABSENSI_SISWA = "setFetchAbsensiSiswa";
export const SET_FETCH_AKTIVITAS_SISWA = "setFetchAktivitasSisa";
export const SET_FETCH_EVENT_SISWA = "setFetchEventSiswa";

export const SET_DASHBOARD_MESSAGE = "setDashboardMessage";

// STUDENT
export const SET_FETCH_DETAIL_STUDENT = "setFetchDetailStudent";
export const SET_UPDATE_STUDENT = "setUpdateStudent";

export const SET_STUDENT_MESSAGE = "setStudentMessage";

// LEVEL
export const SET_FETCH_LEVEL = "setFetchLevel";

export const SET_LEVEL_MESSAGE = "setLevelMessage";

// ABSENSI
export const SET_FETCH_ABSENSI = "setFetchAbsensi";
export const SET_FETCH_DETAIL_ABSENSI = "setFetchDetailAbsensi";

export const SET_ABSENSI_MESSAGE = "setAbsensiMessage";