<template>
  <div>
    <Login />
    <!-- <login v-if="!isMobile()"></login>
    <login-mobile v-else> </login-mobile> -->
  </div>
</template>

<script>
import Login from '@/components/Login/Login.vue';

export default {
  components: {
    Login,
  },
  name: "App",
  data() {
    return {};
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
