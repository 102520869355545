import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1995AE",
        warning: "#FBBC05",
        danger: "#FA3939",
        info: "#277BC0",
        success: "#71D748",
        grey1: "#C9C9C9",
        grey2: "#737373",
        black: "#000000",
      },
    },
  },
});
