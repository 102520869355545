import { POST_LOGIN_ADMIN, POST_RESET_PASSWORD } from "./actions.type";
import {
  SET_LOGIN_AUTH_DATA,
  SET_LOGIN_USER_DATA,
  SET_POST_RESET_PASSWORD,
  SET_LOGIN_ERROR_MESSAGE,
} from "./mutations.type";
import { apiPostAuth } from "@/utils/apiHandler";
import Vue from "vue";

const state = {
  errorLoginMessage: "",
};

const getters = {
  getAdminData(state) {
    const userDataStorage = JSON.parse(localStorage.getItem("user_data"));
    if (state.adminData.username) {
      return state.userData;
    } else {
      return userDataStorage;
    }
  },
  getErrorLoginMessage(state) {
    return state.errorLoginMessage;
  },
};

const actions = {
  [POST_LOGIN_ADMIN]({ commit }, payload) {
    return apiPostAuth("/user/app/login", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_LOGIN_ERROR_MESSAGE, data.error);
      } else {
        Vue.toasted.success(data.message, {
          duration: 3000,
          keepOnHover: true,
        });
        commit(SET_LOGIN_ERROR_MESSAGE, "Success");
        commit(SET_LOGIN_AUTH_DATA, data.access_token);
        commit(SET_LOGIN_USER_DATA, data.data);
      }
    });
  },
  [POST_RESET_PASSWORD]({ commit }, payload) {
    return apiPostAuth("/user/reset-password", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_LOGIN_ERROR_MESSAGE, data.error);
      } else {
        Vue.toasted.success(data.message, {
          duration: 3000,
          keepOnHover: true,
        });
        commit(SET_LOGIN_ERROR_MESSAGE, "Success");
      }
    });
  },
};

const mutations = {
  [SET_LOGIN_AUTH_DATA](state, access_token) {
    localStorage.setItem("access_token", access_token);
  },
  [SET_LOGIN_USER_DATA](state, user_data) {
    const user = {
      id: user_data.id,
      username: user_data.username,
      email: user_data.email,
      phone: user_data.phone,
      role_id: user_data.role_id,
    };
    localStorage.setItem("user_data", JSON.stringify(user));
    let dateNow = new Date();
    localStorage.setItem("expire", dateNow.getTime() + 10 * 60 * 60 * 1000);
  },
  [SET_LOGIN_ERROR_MESSAGE](state, error) {
    state.errorLoginMessage = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
