<template>
  <div>
    <div v-if="isMobile()" class="layout-wrapper d-flex flex-column full-width">
      <slot />
    </div>

    <div v-if="!isMobile()" class="layout-wrapper d-flex flex-row">
      <Loading v-model="getLoading" />
      <Sidebar v-if="!hideSidebar" />
      <div class="d-flex flex-column full-width">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/views/Sidebar/Sidebar.vue";
import Loading from "./Base/Loading.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Sidebar,
    Loading,
  },

  computed: {
    ...mapGetters(["hideSidebar", "getLoading"]),
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.layout-wrapper {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
.full-width {
  width: 100%;
}
</style>
