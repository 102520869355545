<template>
  <v-layout>
    <Sidebar />
    <!-- <sidebar v-if="!isMobile()"></sidebar>
    <sidebar-mobile v-else> </sidebar-mobile> -->
  </v-layout>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';

export default {
  components: {
    Sidebar,
  },
  name: "App",
  data() {
    return {};
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
