<template>
  <v-app>
    <v-main>
      <Layout>
        <router-view />
      </Layout>
    </v-main>
  </v-app>
</template>

<script>
import Layout from "@/components/Main.vue";

export default {
  name: "App",
  components: {
    Layout,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/Poppins-Regular.ttf") format("truetype");
}

.my-input.v-input .v-input__slot {
  border-radius: 10px;
  height: 48px;
}
.my-text-area.v-input .v-input__slot {
  border-radius: 10px;
}

.rounded-hippo {
  border-radius: 10px !important;
}

.font-700-18 {
  font-weight: 700;
  font-size: 18px;
}
.font-700-16 {
  font-weight: 700;
  font-size: 16px;
}

.font-600-16 {
  font-weight: 600;
  font-size: 16px;
}
.font-600-14 {
  font-weight: 600;
  font-size: 14px;
}
.font-600-12 {
  font-weight: 600;
  font-size: 12px;
}

.font-500-16 {
  font-weight: 500;
  font-size: 16px;
}
.font-500-14 {
  font-weight: 500;
  font-size: 14px;
}
.font-500-12 {
  font-weight: 500;
  font-size: 12px;
}

.font-400-18 {
  font-weight: 400;
  font-size: 18px;
}
.font-400-14 {
  font-weight: 400;
  font-size: 14px;
}
.font-400-12 {
  font-weight: 400;
  font-size: 12px;
}
.font-400-10 {
  font-weight: 400;
  font-size: 10px;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(177, 177, 177);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(148, 148, 148);
}
</style>
