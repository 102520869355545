<template>
  <div class="data-container">
    <img
      class="d-block mx-auto"
      src="../assets/logo-hippo.png"
      alt=""
      width="250"
    />
    <h1>I. Kebijakan Privasi</h1>
    <br />
    Kebijakan Privasi ini adalah bentuk komitmen nyata PT. HIPPOCAMPUS GROUP
    INDONESIA (selanjutnya disebut sebagai “Hippocampus”) untuk melindungi dan
    menghargai setiap bentuk informasi pribadi Pengguna pada aplikasi
    Hippocampus. Kebijakan Privasi ini (beserta syarat-syarat penggunaan dari
    aplikasi sebagaimana tercantum dalam “Syarat dan Ketentuan”) mengatur
    tentang perolehan, pengumpulan, pengolahan, dan penganalisisan, penampilan,
    pembukaan, dan / atau segala bentuk pengelolaan yang terkait dengan data
    atau informasi yang pengguna berikan kepada Hippocampus, termasuk data
    pribadi Pengguna, baik pada saat Pengguna melakukan pendaftaran di aplikasi
    dan / atau website, mengakses aplikasi dan / atau website, maupun
    mempergunakan layanan-layanan pada aplikasi dan / atau website. Dengan
    mengakses dan / atau menggunakan layanan Hippocampus, Pengguna menyatakan
    bahwa setiap informasi merupakan informasi dan / atau data yang benar dan
    sah, serta Pengguna memberikan persetujuan kepada Hippocampus untuk
    memperoleh, mengumpulkan, menyimpan, mengelola dan mempergunakan informasi
    dan / atau data tersebut yang tercantum juga didalam Syarat dan Ketentuan
    aplikasi .
    <br />
    <br />
    <h1>II. Kebijakan Umum</h1>
    <br />
    Keseluruhan kebijakan ini dibuat untuk semua pengunjung dan / atau pengguna
    situs Hippocampus agar tunduk kepada syarat dan ketentuan berikut. Dengan
    memasang dan / atau menggunakan Platform Hippocampus, Pengguna telah
    membaca, memahami, dan menyetujui Syarat dan Ketentuan ('SYARAT DAN
    KETENTUAN') pada situs Hippocampus. Kami memilki hak untuk dapat merubah
    syarat dan ketentuan setiap saat, dan perubahan tersebut dapat berlaku
    secara efektif setelah perubahan syarat dan ketentuan dilakukan pada situs
    ini. Pengguna menyetujui untuk meninjau syarat dan ketentuan secara berkala
    untuk mengetahui jenis perubahan yang dimaksudkan. Penggunaan akses layanan
    Hippocampus merupakan bentuk penerimaan anda terhadap syarat dan ketentuan
    yang berlaku. Syarat dan Ketentuan pada platform Hippocampus merupakan
    bentuk dasar dari perjanjian hukum antara penggunaan platform dan layanan
    kami. Kami dapat memberikan kesempatan kepada pengunjung website untuk
    berpartisipasi dalam fitur atau layanan tambahan yang dapat diakses melalui
    situs ini. Pengguna diminta untuk dapat menerima ketentuan tambahan dan /
    atau otorisasi sebelum dapat memiliki hak akses fitur dan layanan tersebut.
    Apabila pengguna tidak yakin kami menyarankan kepada pengguna untuk berhenti
    menggunakan layanan platform Hippocampus.
    <br />
    <br />
    <h1>III. Peringatan</h1>
    <br />
    Perlu ditekankan dan / atau diperhatikan kepada pengguna platform
    Hippocampus semua berita mengenai kesehatan yang terdapat dalam situs
    Hippocampus merupakan bentuk informasi yang tidak bisa digunakan sebagai
    parameter pengganti konsultasi atau berupa tindakan medis. Pengguna tetap
    disarankan untuk melakukan konsultasi dengan dokter atau tenaga kesehatan
    yang berlisensi terhadap masalah kesehatan. Berhati-hati terhadap segala
    bentuk penipuan, pemerasan, pemalsuan identitas dan tindak kejahatan lain
    yang dilakukan oleh orang atau pihak yang mengatasnamakan Hippocampus.
    Setiap karyawan Hippocampus dilengkapi dengan Kartu dan / atau Tanda
    Pengenal Identitas saat melakukan kegiatan Hippocampus.
    <br />
    <br />
    <h1>IV. Layanan Kami</h1>
    <br />
    Sebagai bagian dari Layanan, kami akan menghubungkan pengguna platform
    Hippocampus dengan Phlebotomist professional sebagai bagian dari layanan
    kami. Kami akan menampilkan laboratorium dan / atau unit layanan kesehatan
    yang bekerjasama dengan platrform Hippocampus sehingga pengguna memiliki
    tingkat kepercayaan terhadap sampel yang diperiksa. Hippocampus juga
    memfasilitasi pengguna dalam bentuk pemesanan yang masuk melalui platform
    sampai pembayaran dilakukan oleh pengguna.
    <br />
    <br />
    <h1>V. Koneksi Jaringan</h1>
    <br />
    Hippocampus tidak bertanggung jawab terhadap segala kesalahan, penundaan,
    dan masalah lain yang berkaitan dengan tampilan konten dan proses pemesanan
    masuk pada platform Hippocampus yang disebabkan adanya kegagalan internet
    (jaringan). Kami menyarankan kepada pengguna platform Hippocampus untuk
    memeriksa internet (jaringan) koneksi sebelum menggunakan platform.
    <br />
    <br />
    <h1>VI. Distribusi</h1>
    <br />
    Hippocampus akan menentukan format tampilan dan metode reservasi pemesanan
    atas Kebijakan Kami sehingga pengguna platform dapat melakukan pemesanan
    dalam waktu yang terbatas atau dalam waktu yang direncakan, dengan patuh dan
    / atu tunduk pada masa berlaku pemesanan yang telah ditetapkan.
    <br />
    <br />
    <h1>VII. Keluhan</h1>
    <br />
    Jika terdapat keluhan atas proses pengambilan sampel yang telah dilakukan,
    Kami dapat meneruskan keluhan tersebut kepada Pihak yang berwenang pada
    platform Hippocampus sehingga keluhan dapat terselesaikan dengan baik. Perlu
    dipahami apabila dalam melakukan pemesanan terdapat kesalahan dalam
    memberikan identitas pengguna, bukan menjadi tanggung jawab kami sebagai
    platform dan merupakan tanggung jawab pengguna tersebut. Demikian, apabila
    keluhan disebabkan oleh adanya kesalahan pada platform kami, hal tersebut
    menjadi tanggung jawab kami sebagai pengelola platform Hippocampus.
    <br />
    <br />
    <h1>VIII. Akurasi</h1>
    <br />
    Kami memiliki wewenang penuh atas informasi yang ditampilkan sehubungan
    dengan pemesanan yang telah dilakukan oleh pengguna melalui platform
    Hippocampus. Hal ini tidak terbatas kepada penunjukan laboratorium dan /
    atau Unit Layanan Kesehatan rujukan, Penghapusan informasi yang tidak benar,
    serta informasi yang melanggar ketentuan perundang-undangan seperti konten
    pornografi, pencemaran nama baik, ujaran kebencian, atau informasi lainnya
    yang melanggar ketentuan perundang-undangan ITE. Pengguna bertanggung jawab
    untuk memberikan informasi sehubungan dengan pemesanan yang dilakukan dan
    memastikan kebeneran informasi yang diberikan.
    <br />
    <br />
    <h1>IX. Permasalahan Teknologi</h1>
    <br />
    Kami tidak memberikan jaminan pengguna platform Hippocampus akan terbebas
    dari permasalahan teknologi seperti ketiadaan informasi, penghentian,
    gangguan layanan, virus atau worm, dan pengguna mengerti serta bertanggung
    jawab untuk melaksanakan sesuai prosedur dan telah memastikan untuk memenuhi
    persyaratan tertentu, terkait dengan ketepatan input dan output data.
    <br />
    <br />
    <h1>X. Hak Cipta</h1>
    <br />
    Konten dan desain dari platform Hippocampus dilindungi oleh UU No. 19 Tahun
    2002 tentang Hak Cipta di Indonesia. Anda tida diperkenankan untuk menyalin,
    memproduksi ulang, mempublikasikan kembali, mengunggah, menuliskan,
    menampilkan, mengirimkan atau menyusun materi-materi tersebut tanpa
    persetujuan tertulis dari pemegang hak cipta. Pengguna diizinkan untuk
    melihat, mengunduh, menampilkan dan mencetak satu Salinan bahan-bahan
    tersebut pada computer pribadi, hanya untuk penggunaan non-komersial, dengan
    syarat: pengguna tidak mengubah atau memodifikasi bahan dengan cara apapun;
    pengguna mengerti dan menyetuji bahwa tindakan-tindakan tersebut tidak akan
    mengakibatkan beralihnya hak cipta. Hippocampus dibebaskan dari segala
    tuntutan dan pertanggungjawaban atas pelanggaran hak terutama yang berkaitan
    dengan Hak Kekayaan Intelektual (HAKI) yang dilakukan oleh pihak ketiga
    terhadap Konten yang ditempatkan atau ditayangkan di Hippocampus.
    <br />
    <br />
    <h1>XI. Penanggungan Kerugian</h1>
    <br />
    Pengguna setuju bahwa anda akan menanggung kerugian kami sebagai pemiliki
    platform Hippocampus terhadap kerugian, kewajiban, penilaian, biaya atau
    biaya (atas pengacara yang wajar dan biaya) yang timbul dari kesalahan
    pengguna dalam pengoperasian platform Hippocampus.
    <br />
    <br />
    <h1>XII. Penyelesaian Sengketa</h1>
    <br />
    Pengguna menyetujui bahwa setiap sengketa atau klaim (termasuk klaim
    terhadap kerugian personal) sehubungan dengan penggunaan platform
    Hippocampus tunduk pada yurisdiksi eksklusif hukum Indonesia dan akan
    diadili sesuai dengan Hukum Negara Indonesia. Perjanjian ini juga berlaku
    untuk setiap orang yang mengklaim atas nama anda.
  </div>
</template>
<script>
import { HIDE_SIDEBAR } from "../store/actions.type";

export default {
  name: "Privacy-Policy",
  async mounted() {
    await this.$store.dispatch(HIDE_SIDEBAR, true);
  },
};
</script>
<style>
.data-container {
  margin: 20px 0;
  overflow: auto;
}
</style>
