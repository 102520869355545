import Vue from "vue";
import Vuex from "vuex";
import utilsModule from "./utils.module";
import authModule from "./auth.module";
import dashboardModule from "./dashboard.module";
import siswaModule from "./siswa.module";
import levelModule from "./level.module";
import absensiModule from "./absensi.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    utilsModule,
    authModule,
    dashboardModule,
    siswaModule,
    levelModule,
    absensiModule,
  },
});
