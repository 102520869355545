<template>
  <v-overlay
    absolute
    v-if="value"
    class="loading wrap-loading"
    @wheel.prevent
    @touchmove.prevent
    @scroll.prevent
    color="white"
  >
    <div class="">
      <div class="sk-chase text-center">
        <img src="../../assets/logo-hippo.png" alt="" srcset="" width="250px" />
        <h4 class="mt-2 mb-1 text--primary">Mohon tunggu sebentar</h4>
        <v-progress-linear class="mt-2" indeterminate></v-progress-linear>
      </div>
    </div>
  </v-overlay>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, default: false },
  },
  methods: {},
  mounted() {},
}
</script>
<style scoped>
.loading {
  height: 100vh !important;
  position: absolute !important;
  bottom: 0;
  overscroll-behavior: contain !important;
  z-index: 1000;
  backdrop-filter: blur(10px) !important;
}

.sk-chase {
  position: relative;
}

.wrap-loading {
  z-index: 10000 !important;
}
</style>
