<template>
  <v-card>
    <v-navigation-drawer permanent class="sidebar-wrapper" width="340">
      <v-list-item one-line>
        <v-list-item-icon class="mx-auto">
          <img src="../assets/logo-hippo.png" alt="" style="width: 150px" />
        </v-list-item-icon>
      </v-list-item>

      <v-list-item-group active-class="bg-active" v-model="model">
        <v-list class="px-6 scrollable-sidebar" nav style="margin-top: 50px">
          <v-list-item link :to="'/' + item.name" class="px-6 mb-6" v-for="item in items" :key="item.name">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-item-group>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", name: "dashboard" },
        { title: "Absensi", icon: "mdi-account-group-outline", name: "absensi" },
        { title: "Profil", icon: "mdi-account-outline", name: "profil" },
      ],
      model: 0,
    };
  },
  methods: {},
};
</script>
<style scoped>
.sidebar-wrapper {
  height: 800vh !important;
  background-color: rgba(14, 183, 176, 0.1);
  box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.05);
}
.bg-active {
  background-color: #0eb7b0;
  color: white !important;
}
.scrollable-sidebar {
  overflow-y: hidden;
  height: 70vh;
}
.scrollable-sidebar:hover {
  overflow-y: scroll;
}
</style>
