<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card rounded="lg">
      <v-card-title class="px-3 py-4">
        <span class="pl-2">Konfirmasi</span>
      </v-card-title>

      <v-card-text>
        <div class="d-flex pt-4">
          <img src="../../assets/Danger-icon.svg" class="mr-4" alt="" />
          <div v-if="formType === 'edit'" class="font-body">
            Data {{ title }} akan diedit dari sistem, Apakah anda yakin untuk
            mengedit {{ title }} ini ?
          </div>
          <div v-else class="font-body">
            Data {{ title }} akan dihapus dari sistem, Apakah anda yakin untuk
            menghapus {{ title }} ini ?
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-row justify="end" class="pa-2">
          <v-btn
            @click="closeDialog()"
            class="rounded-lg mr-5"
            :disabled="loading"
            outlined
            color="warning"
            width="113"
            height="57"
            dark
          >
            Batal
          </v-btn>
          <v-btn
            v-if="formType === 'edit'"
            @click="$emit('action')"
            class="rounded-lg"
            color="primary"
            :loading="loading"
            width="113"
            height="57"
            dark
          >
            Edit
          </v-btn>
          <v-btn
            v-else
            @click="$emit('action')"
            class="rounded-lg"
            color="danger"
            :loading="loading"
            width="113"
            height="57"
            dark
          >
            Hapus
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    formType: { type: String, default: "edit" },
    loading: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
.font-body {
  font-size: 20px;
  color: black;
}
</style>
