<template>
  <v-row>
    <v-col>
      <v-img
        :src="require('../../assets/logo-hippo.png')"
        width="136"
        class="ml-8 mt-8"
      />
      <div class="text-center" style="margin-top: 115px">
        <v-img
          :src="require('../../assets/Vector-Login.svg')"
          height="455"
          width="455"
          class="mx-auto"
        />
        <div class="text-heading-1">Di Hippocampus belajar jadi lebih asik</div>
        <div class="text-heading-2 mt-3">
          Temukan cara belajar yang beda. Yuk cobain!
        </div>
      </div>
    </v-col>
    <v-col
      class="d-flex justify-center align-center full-height"
      cols="4"
      style="background-color: #0eb7b0"
    >
      <v-card
        v-if="forgotPassword"
        class="pa-10"
        rounded="lg"
        height="363"
        width="415"
      >
        <div class="text-title-green">Lupa Kata Sandi</div>
        <div class="text-medium-grey mt-3">
          Masukan alamat email yang telah terdaftar menerima email reset kata
          sandi
        </div>
        <div class="mt-9">
          <label class="text-title-form">Email</label>
          <v-text-field
            outlined
            v-model="email"
            placeholder="Email"
            color="#0EB7B0"
            class="my-input mt-2"
            dense
            type="email"
          >
            <template v-slot:prepend-inner>
              <v-icon class="mr-2">mdi-email-outline</v-icon>
            </template>
          </v-text-field>
        </div>
        <v-btn rounded color="#0EB7B0" width="100%" class="mt-7" large dark>
          Masuk
        </v-btn>
      </v-card>
      <!-- past height 589 -->
      <v-card v-else class="pa-10" rounded="lg" height="400" width="415">
        <div class="text-heading-1 text-center">Sign in</div>
        <v-form ref="form">
          <div>
            <label class="text-title-form">Email</label>
            <v-text-field
              outlined
              v-model="email"
              :rules="emailRules"
              placeholder="Email"
              color="#0EB7B0"
              class="my-input mt-2"
              dense
              type="email"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-email-outline</v-icon>
              </template>
            </v-text-field>
          </div>
          <div>
            <label class="text-title-form">Password</label>
            <v-text-field
              outlined
              v-model="password"
              :rules="inputRules"
              placeholder="Password"
              color="#0EB7B0"
              class="my-input mt-2"
              dense
              :type="showPassword ? 'password' : 'text'"
              :append-icon="
                showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              "
              @click:append="() => (showPassword = !showPassword)"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mr-2">mdi-lock-outline</v-icon>
              </template>
            </v-text-field>
          </div>
        </v-form>
        <!-- <div class="d-flex justify-end mb-7" style="width: 100%">
          <small @click=" forgotPassword = true" class="text-small ml-auto">Lupa kata sandi?</small>
        </div> -->

        <v-btn
          @click="login()"
          rounded
          :loading="loadingLogin"
          color="#0EB7B0"
          width="100%"
          large
          dark
        >
          Masuk
        </v-btn>

        <!-- <v-row class="mt-5">
          <v-col class="my-auto"><hr></v-col>
          <v-col class="text-small-grey">atau daftar dengan</v-col>
          <v-col class="my-auto"><hr></v-col>
        </v-row> -->

        <!-- <v-btn rounded class="black--text mt-6" color="#FFFFFF" width="100%" large dark>
          <v-img :src="require('../../assets/Google-Logo.svg')" class="mr-3" height="25" max-width="25"></v-img>
          Sign up with Google
        </v-btn>

        <v-btn rounded class="mt-4" color="#285D8E" width="100%" large dark>
          <v-img :src="require('../../assets/Facebook-Logo.svg')" class="mr-3" height="25" max-width="25"></v-img>
          Sign up with Facebook
        </v-btn> -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { HIDE_SIDEBAR, POST_LOGIN_ADMIN } from "../../store/actions.type";

export default {
  name: "Login",
  data() {
    return {
      showPassword: true,
      forgotPassword: false,
      loadingLogin: false,

      email: null,
      password: null,

      emailRules: [
        (v) => !!v || "E-mail wajib diisi",
        (v) => /.+@.+\..+/.test(v) || "E-mail harus valid",
      ],
      inputRules: [
        (v) => {
          if (!v) {
            return "Password harus diisi";
          }
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getErrorLoginMessage"]),
  },
  async mounted() {
    await this.$store.dispatch(HIDE_SIDEBAR, true);
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.loadingLogin = true;
        const loginData = new FormData();

        loginData.append("email", this.email);
        loginData.append("password", this.password);

        await this.$store.dispatch(POST_LOGIN_ADMIN, loginData);

        if (this.getErrorLoginMessage == "Success") {
          this.$router.push({ path: "/dashboard" });
        }
      } else if (this.fileKey == null) this.secretStatus = false;

      this.loadingLogin = false;
    },
  },
};
</script>

<style scoped>
.full-height {
  min-height: 100vh;
}

.text-heading-1 {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 700;
  font-size: 35px;
  line-height: auto;
  color: #0eb7b0;
}
.text-heading-2 {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 22px;
  line-height: auto;
  color: #000000;
}

.text-title {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 600;
  font-size: 20px;
  line-height: auto;
  color: #000000;
}

.text-title-green {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 600;
  font-size: 20px;
  line-height: auto;
  color: #0eb7b0;
}

.text-medium-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 12px;
  line-height: auto;
  color: #737373;
}

.text-small {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 9px;
  line-height: auto;
  color: #0eb7b0;
  cursor: pointer;
}

.text-small-grey {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 9px;
  line-height: auto;
  color: #b6b6b6;
}

.text-title-form {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 14px;
  line-height: auto;
  color: #000000;
}

.upload-btn {
  font-family: "Poppins";
  font-style: "normal";
  font-weight: 400;
  font-size: 11px;
  border: 2px solid #c9c9c9;
  height: 42px;
  cursor: pointer;
}

.upload-btn:hover {
  border: 2px solid #0eb7b0;
}
</style>
