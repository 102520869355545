<template>
  <div>
    <Absensi />
    <!-- <login v-if="!isMobile()"></login>
    <login-mobile v-else> </login-mobile> -->
  </div>
</template>

<script>
import Absensi from '@/components/Dashboard/Absensi.vue';

export default {
  components: {
    Absensi,
  },
  name: "AbsensiView",
  data() {
    return {};
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
