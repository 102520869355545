import {
  HIDE_SIDEBAR,
  FETCH_PROVINSI,
  FETCH_KOTA,
  FETCH_KECAMATAN,
  LOADING,
} from "./actions.type";

import {
  SET_HIDE_SIDEBAR,
  SET_FETCH_PROVINSI,
  SET_FETCH_KOTA,
  SET_FETCH_KECAMATAN,
  SET_LOADING,
} from "./mutations.type";

import { apiGetWilayah } from "@/utils/apiHandler";

const state = {
  hideSidebar: false,
  provincesData: [],
  regenciesData: [],
  districtsData: [],
  loading: false,
};

const getters = {
  hideSidebar: (state) => {
    return state.hideSidebar;
  },
  getProvincesData(state) {
    return state.provincesData;
  },
  getRegenciesData(state) {
    return state.regenciesData;
  },
  getDistrictsData(state) {
    return state.districtsData;
  },
  getLoading(state) {
    return state.loading;
  },
};

const actions = {
  [HIDE_SIDEBAR]({ commit }, hideSidebar) {
    commit(SET_HIDE_SIDEBAR, hideSidebar);
  },
  [FETCH_PROVINSI]({ commit }) {
    return apiGetWilayah("/provinces.json").then(({ data }) => {
      commit(SET_FETCH_PROVINSI, data);
    });
  },
  [FETCH_KOTA]({ commit }, payload) {
    return apiGetWilayah(`/regencies/${payload}.json`).then(({ data }) => {
      commit(SET_FETCH_KOTA, data);
    });
  },
  [FETCH_KECAMATAN]({ commit }, payload) {
    return apiGetWilayah(`/districts/${payload}.json`).then(({ data }) => {
      commit(SET_FETCH_KECAMATAN, data);
    });
  },
  [LOADING]({ commit }, loading) {
    commit(SET_LOADING, loading);
  },
};

const mutations = {
  [SET_HIDE_SIDEBAR](state, hideSidebar) {
    state.hideSidebar = hideSidebar;
  },
  [SET_FETCH_PROVINSI](state, provincesData) {
    state.provincesData = provincesData;
  },
  [SET_FETCH_KOTA](state, regenciesData) {
    state.regenciesData = regenciesData;
  },
  [SET_FETCH_KECAMATAN](state, districtsData) {
    state.districtsData = districtsData;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
