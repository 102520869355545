import { FETCH_DASHBOARD_DBSISWA } from "./actions.type";
import {
  SET_FETCH_DASHBOARD_DBSISWA,
  SET_DASHBOARD_MESSAGE,
} from "./mutations.type";
import { apiPost } from "@/utils/apiHandler";
import Vue from "vue";

const state = {
  dashboardSiswaDbData: [],

  dashboardMessage: "",
};

const getters = {
  getDashboardSiswaDbData(state) {
    return state.dashboardSiswaDbData;
  },
  getDashboardMessage(state) {
    return state.dashboardMessage;
  },
};

const actions = {
  [FETCH_DASHBOARD_DBSISWA]({ commit }, payload) {
    return apiPost("/dashboard/student", payload).then(({ data }) => {
      if (data.error) {
        commit(SET_DASHBOARD_MESSAGE, data.error);
      } else {
        commit(SET_FETCH_DASHBOARD_DBSISWA, data.data);
      }
    });
  },
};

const mutations = {
  [SET_FETCH_DASHBOARD_DBSISWA](state, dashboardSiswaDbData) {
    state.dashboardSiswaDbData = dashboardSiswaDbData;
  },
  [SET_DASHBOARD_MESSAGE](state, message) {
    state.dashboardMessage = message;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
