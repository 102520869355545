<template>
  <div>
    <Dashboard />
    <!-- <login v-if="!isMobile()"></login>
    <login-mobile v-else> </login-mobile> -->
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard/Dashboard.vue';

export default {
  components: {
    Dashboard,
  },
  name: "DashboardView",
  data() {
    return {};
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
