import axios from "axios";

const authInstance = axios.create({
  headers: {
    common: {
      Accept: "text/plain, */*",
    },
  },
  baseURL: process.env.VUE_APP_AUTH_API,
});

authInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    config.headers["Authorization"] =
      "Basic aGV5eW9FZHVjYXRpb25Nb2JpbGU6SDNZWTAzRFVDNFQxME5NMEIxTDM=";
    return config;
  } else {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const dashboardInstance = axios.create({
  headers: {
    common: {
      Accept: "text/plain, */*",
    },
  },
  baseURL: process.env.VUE_APP_DASHBOARD_API,
});

dashboardInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (!token) {
    config.headers["Authorization"] =
      "Basic aGV5eW9FZHVjYXRpb25XZWI6SDNZWTAzRFVDNFQxME5XM0I=";
    return config;
  } else {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const wilayahInstance = axios.create({
  headers: {
    common: {
      Accept: "text/plain, */*",
    },
  },
  baseURL: process.env.VUE_APP_WILAYAH_API,
});

export { authInstance, dashboardInstance, wilayahInstance };
