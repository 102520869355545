import { authInstance, dashboardInstance, wilayahInstance } from "./api.js";
import Vue from "vue";

const errorApiHandler = (error) => {
  Vue.toasted.error(error.response.data.error, {
    duration: 3000,
    keepOnHover: true,
  });
};

const apiPostAuth = async (url, data) => {
  try {
    let response = await authInstance.post(url, data);
    return response;
  } catch (error) {
    errorApiHandler(error);
    return error.response;
  }
};

const apiGetWilayah = async (url) => {
  try {
    let response = await wilayahInstance.get(url);
    return response;
  } catch (error) {
    errorApiHandler(error);
    return error.response;
  }
};

const apiGet = async (url, data) => {
  try {
    let response = await dashboardInstance.get(url, data);
    return response;
  } catch (error) {
    errorApiHandler(error);
    return error.response;
  }
};

const apiPost = async (url, data) => {
  try {
    let response = await dashboardInstance.post(url, data);
    return response;
  } catch (error) {
    errorApiHandler(error);
    return error.response;
  }
};

const apiPut = async (url, data) => {
  try {
    let response = await dashboardInstance.put(url, data);
    return response;
  } catch (error) {
    errorApiHandler(error);
    return error.response;
  }
};

const apiDelete = async (url, data) => {
  try {
    let response = await dashboardInstance.delete(url, data);
    return response;
  } catch (error) {
    errorApiHandler(error);
    return error.response;
  }
};

export { apiPostAuth, apiGetWilayah, apiGet, apiPost, apiPut, apiDelete };
