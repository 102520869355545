<template>
  <div>
    <Navbar />
    <div class="data-container ma-0">
      <div v-if="pageType === 1">
        <v-btn
          @click="pageType = 0"
          class="rounded-lg mb-5"
          color="primary"
          width="209"
          height="58"
          dark
        >
          <v-icon>mdi-step-backward</v-icon>
          Kembali
        </v-btn>
      </div>
      <v-card v-if="pageType === 0">
        <div class="pt-6 px-6 pb-7">
          <div class="font-400-18 mb-7">Informasi Presensi</div>

          <!-- <v-select
            v-model="filterBulan"
            :items="dataBulan"
            outlined
            placeholder="Pilih Jenjang Sekolah"
            class="my-input mt-2"
            type="text"
            style="width: 294px"
          ></v-select> -->

          <v-data-table
            :headers="tableHeaders"
            :items="getAbsensiData"
            :loading="fetchData"
            class="elevation-1 mt-8"
            @click:row="showDetailAbsensi"
          >
            <!-- Template Hari -->
            <template v-slot:item.hari="{ item }">
              <span>{{ getDayName(item.date) }}</span>
            </template>

            <!-- Template Status -->
            <template v-slot:item.status="{ item }">
              <div>
                <v-chip v-if="item.status == 'present'" outlined color="primary">
                  Hadir
                </v-chip>
                <v-chip v-else-if="item.status == 'not present'" outlined color="danger">Tidak Hadir</v-chip>
                <v-chip v-else-if="item.status == 'sick'" outlined color="primary">Sakit</v-chip>
                <v-chip v-else-if="item.status == 'permission'" outlined color="info">Izin</v-chip>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <v-row v-if="pageType === 1">
        <v-col>
          <v-card class="py-8 px-7" rounded="xl">
            <div class="font-700-18 my-6">Detail Absen</div>
            <div
              class="pa-4"
              style="background-color: #f8f4f4; border-radius: 0"
            >
              <div class="font-600-16">{{ getDetailAbsensiData.code }} - {{ getDetailAbsensiData.class }}</div>
              <v-divider class="my-5"></v-divider>
              <div class="d-flex justify-space-between my-3">
                <div class="font-500-16" style="color: #737373">Hari</div>
                <div class="font-600-16">
                  {{ getDayName(getDetailAbsensiData.date) }}
                </div>
              </div>
              <div class="d-flex justify-space-between my-3">
                <div class="font-500-16" style="color: #737373">Tanggal</div>
                <div class="font-600-16">{{ getDetailAbsensiData.date }}</div>
              </div>
              <div class="d-flex justify-space-between my-3">
                <div class="font-500-16" style="color: #737373">Jam</div>
                <div class="font-600-16">
                  {{ getDetailAbsensiData.time_start }}
                </div>
              </div>
              <div class="d-flex justify-space-between my-3">
                <div class="font-500-16" style="color: #737373">
                  Mata Pelajaran
                </div>
                <div class="font-600-16">
                  {{ getDetailAbsensiData.subject }}
                </div>
              </div>
              <div class="d-flex justify-space-between my-3">
                <div class="font-500-16" style="color: #737373">Tutor</div>
                <div class="font-600-16">
                  {{ getDetailAbsensiData.tutor_name }}
                </div>
              </div>
              <div class="d-flex justify-space-between my-3">
                <div class="font-500-16" style="color: #737373">
                  Durasi Belajar
                </div>
                <div class="font-600-16">
                  {{ getDetailAbsensiData.duration }} Menit
                </div>
              </div>
              <v-divider class="my-5"></v-divider>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="py-8 px-7" rounded="xl" style="height: 100%">
            <div class="font-600-16 my-6">Dokumentasi</div>
            <div
              v-if="getDetailAbsensiData.image_url === null"
              class="foto-container"
              style="border-radius: 0"
            ></div>
            <v-img
              v-else
              class="mx-auto"
              max-width="420"
              :src="getDetailAbsensiData.image_url"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "../Navbar/Navbar.vue";
import moment from "moment";
import { FETCH_ABSENSI, FETCH_DETAIL_ABSENSI, LOADING } from "@/store/actions.type";

export default {
  name: "Absensi",
  components: { Navbar },
  data() {
    return {
      fetchData: false,
      filterBulan: null,
      pageType: 0,

      tableHeaders: [
        { text: "Hari", value: "hari", align: "center" },
        { text: "Tangal", value: "date", align: "center" },
        { text: "Jam", value: "time_start", align: "center" },
        { text: "Tutor", value: "tutor_name", align: "center" },
        { text: "Mata Pelajaran", value: "subject", align: "center" },
        { text: "Status", value: "status", align: "center" },
      ],

      dataBulan: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    };
  },
  computed: {
    ...mapGetters(["getAbsensiData", "getDetailAbsensiData"]),
  },
  async mounted() {
    this.fetchData = true;
    await this.$store.dispatch(LOADING, true);
    await this.$store.dispatch(FETCH_ABSENSI, {
      user_id: JSON.parse(localStorage.getItem("user_data")).id,
    });
    await this.$store.dispatch(LOADING, false);
    this.fetchData = false;
  },
  methods: {
    async showDetailAbsensi(item) {
      this.fetchData = true;
      await this.$store.dispatch(FETCH_DETAIL_ABSENSI, { id: item.id });
      this.fetchData = false;

      this.pageType = 1;
    },
    getDayName(date) {
      return moment(date).locale("id").format("dddd");
    },
  },
};
</script>

<style>
.data-container {
  padding-top: 20px;
  padding-left: 36px;
  padding-right: 36px;
  overflow-y: scroll;
  height: 100vh;
}
.foto-container {
  width: 100%;
  height: 214px;
  background-color: #f8f4f4;
}
</style>
